.hero {
  display: flex;
  justify-content: space-between;
}

.left-h {
  padding: 1.5rem 2rem 2rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.the_best_ad {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-items: flex-start;
}
.the_best_ad > div {
  position: absolute;
  background-color: var(--orange);
  height: 80%;
  width: 5.4rem;
  border-radius: 3rem;
  left: 8px;
}
.the_best_ad > span {
  z-index: 2;
}
.right-h {
  flex: 1;
  position: relative;
  background-color: var(--orange);
}

.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}
.header-menu > li {
  cursor: pointer;
  transition: 0.2s linear;
}
.header-menu > li:hover {
  cursor: pointer;
  color: var(--orange);
}

.hero_text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}

.hero_text .span {
  font-size: 1rem;
  font-weight: 200;
  text-transform: capitalize;
  letter-spacing: 1px;
  width: 80%;
}

.figures {
  display: flex;
  gap: 2rem;
}

.figures > div {
  display: flex;
  flex-direction: column;
}

.figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}
.figures > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}

.hero_btn {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}

.hero_btn > :nth-child(1) {
  color: white;
  background-color: var(--orange);
  width: 8rem;
}
.hero_btn > :nth-child(2) {
  color: white;
  background-color: transparent;
  border: 2px solid var(--orange);
  width: 8rem;
}

.right-h > .btn {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
}

.heart-rate {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  right: 4rem;
  top: 7rem;
}
.heart-rate > img {
  width: 2rem;
}

.heart-rate > span:nth-child(2) {
  color: var(--gray);
}
.heart-rate > span:nth-child(3) {
  color: white;
  font-size: 1.5em;
}

.hero_image {
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 23rem;
}

.hero_image_back {
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}

.calories {
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: 15rem;
  top: 32rem;
  right: 28rem;
  position: absolute;
}

.calories > img {
  width: 3rem;
}

.calories > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.calories > div > :nth-child(1) {
  color: var(--gray);
}
.calories > div > :nth-child(2) {
  color: white;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .hero_blur {
    width: 14rem;
  }
  .the_best_ad {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }

  .hero_text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero_text > div:nth-child(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }

  .hero_btn {
    justify-content: center;
  }

  .figures > div > span:nth-of-type(1) {
    font-size: large;
  }
  .figures > div > span:nth-of-type(2) {
    font-size: small;
  }

  .right-h {
    background: none;
    position: relative;
  }
  .heart-rate {
    top: 2rem;
    left: 1rem;
  }
  .calories {
    position: relative;
    left: 2rem;
    top: 5rem;
  }
  .calories > img {
    width: 2rem;
  }
  .calories > div > :nth-of-type(2) {
    font-size: 1rem;
    color: white;
  }
  .hero_image {
    position: relative;
    width: 15rem;
    top: 4rem;
    left: 7rem;
    align-self: center;
  }

  .hero_image_back {
    width: 15rem;
    left: 2rem;
    top: 0rem;
  }
}
